import React, { useRef, type RefObject, createRef, useState } from "react";
import AuthLayout from "./AuthLayout";
import { AuthWrapper } from "../style";
import { Form, type InputRef, type RefSelectProps } from "antd";
import CustomButton from "components/CustomButton";
import { InputItem, RoundedButton, SelectItem } from "components/commonCustom";
import { idCheckApi, nameCheckApi, recCodeCheckApi, registerApi } from 'apis'
import { useDispatch, useSelector } from "react-redux";
import types from "store/actions/types";
type Refs = {
  id: RefObject<InputRef>
  password: RefObject<InputRef>
  rePassword: RefObject<InputRef>
  nickname: RefObject<InputRef>,
  phone: RefObject<InputRef>,
  bankName: RefObject<RefSelectProps>,
  accountNumber: RefObject<InputRef>,
  accountHolder: RefObject<InputRef>,
  exchangePassword: RefObject<InputRef>,
  recommender: RefObject<InputRef>,
}

const RegisterModal = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [idCheck, setIdCheck] = useState<boolean>(false)
  const [nameCheck, setNameCheck] = useState<boolean>(false)
  const [recCodeCheck, setRecCodeCheck] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)
  const selectRef = useRef('');

  const refs = useRef<Refs>({
    id: createRef(),
    password: createRef(),
    rePassword: createRef(),
    nickname: createRef(),
    phone: createRef(),
    bankName: createRef(),
    accountNumber: createRef(),
    accountHolder: createRef(),
    exchangePassword: createRef(),
    recommender: createRef()
  });

  const options = [
    {
      value: '',
      label: '은행을 선택해 주세요.'
    },
    {
      value: '국민은행',
      label: '국민은행'
    },
    {
      value: '농협',
      label: '농협'
    },
    {
      value: '새마을금고',
      label: '새마을금고'
    },
    {
      value: '신한은행',
      label: '신한은행'
    },
    {
      value: '하나은행',
      label: '하나은행'
    },
    {
      value: '카카오뱅크',
      label: '카카오뱅크'
    },
    {
      value: '신협',
      label: '신협'
    },
    {
      value: '수협',
      label: '수협'
    },
    {
      value: '우리은행',
      label: '우리은행'
    },
    {
      value: '우체국',
      label: '우체국'
    },
    {
      value: '기업은행',
      label: '기업은행'
    },
    {
      value: '대구은행',
      label: '대구은행'
    },
    {
      value: '부산은행',
      label: '부산은행'
    },
    {
      value: '제일은행',
      label: '제일은행'
    },
    {
      value: '씨티은행',
      label: '씨티은행'
    },
    {
      value: '경남은행',
      label: '경남은행'
    },
    {
      value: '산업은행',
      label: '산업은행'
    },
  ]
  const popupStates = useSelector((state: any) => state.waitting)

  const handleSubmit = async (values: any) => {

    if (form.getFieldValue('password') !== form.getFieldValue('repassword')) {
      alert('비밀번호가 일치하지 않습니다.')
    } 
    // else if (!recCodeCheck) {
    //   alert('추천인을 확인해주세요.')
    // } else if (!nameCheck) {
    //   alert('이름을 확인해주세요.')
    // } else if (!idCheck) {
    //   alert('아이디를 확인해주세요')
    // } 
    else {
      if(!selectRef.current) return alert('select 은행명')
      setDisable(true)
      try {
        dispatch({ type: 'WAITTING' })
        const res: any = await registerApi({
          mb_id: values.id,
          mb_password: values.password,
          mb_repassword: values.repassword,
          mb_name: values.nickName,
          mb_hp1: values.phone,
          mb_bank_name: selectRef.current,
          mb_bank_no: values.bankNumber,
          mb_bank_own: values.person,
          mb_exch_pw: values.changePassword,
          mb_rec_id: values.recCode
        })
        // console.log(res);
        if (res.result) {
          // console.log(res);
          alert(res.message)
          setNameCheck(true)
          setDisable(false)
          dispatch({type: types.CLOSE_MODAL})
        } else {
          alert(res.error.message)
          setDisable(false)
        }
      } catch (error) {
        console.log(error)
        setDisable(false)
      }finally {
        dispatch({ type: 'STOP_WAITTING' })
      }
    }

  }

  const checkId = async () => {
    if (form.getFieldValue('id')) {
      try {
        const res: any = await idCheckApi({
          id: form.getFieldValue('id')
        })
        // console.log(res);
        if (res.result) {
          // console.log(res);
          alert(res.message)
          setIdCheck(true)
        } else {
          alert(res.error.message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const checkName = async () => {
    if (form.getFieldValue('nickName')) {
      try {
        const res: any = await nameCheckApi({
          name: form.getFieldValue('nickName')
        })
        // console.log(res);
        if (res.result) {
          // console.log(res);
          alert(res.message)
          setNameCheck(true)
        } else {
          alert(res.error.message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const checkRecCode = async () => {
    if (form.getFieldValue('recCode')) {
      try {
        const res: any = await recCodeCheckApi({
          id: form.getFieldValue('recCode')
        })
        // console.log(res);
        if (res.result) {
          // console.log(res);
          alert(res.message)
          setRecCodeCheck(true)
        } else {
          alert(res.error.message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <AuthLayout>
      <AuthWrapper className="register">
        <div className="title">
          가입신청
        </div>
        <Form name="myForm" form={form} initialValues={{ remember: true }} className="register" onFinish={handleSubmit}>
          <div className="layout-form">
            <div className="form-column">
              <div className="form-item">
                <label>아이디* </label>
                <Form.Item name='id'>
                  <InputItem required ref={refs.current.id} placeholder="영문, 숫자, 만 입력" />
                </Form.Item>
                <div className="rounded-btn">
                  <RoundedButton type="button" onClick={checkId} >중복확인</RoundedButton>
                </div>
              </div>
              <div className="form-item">
                <label>비밀번호*</label>
                <Form.Item name='password'>
                  <InputItem type="password" required ref={refs.current.password} placeholder="비밀번호" />
                </Form.Item>
              </div>
              <div className="form-item">
                <label>비밀번호 재확인*</label>
                <Form.Item name='repassword'>
                  <InputItem type="password" required ref={refs.current.rePassword} placeholder="비밀번호 재확인" />
                </Form.Item>
              </div>
              <div className="form-item">
                <label>닉네임*</label>
                <Form.Item name='nickName'>
                  <InputItem required ref={refs.current.nickname} placeholder="닉네임 입력 (2~10)" />
                </Form.Item>
                <div className="rounded-btn">
                  <RoundedButton type="button" onClick={checkName} >중복확인</RoundedButton>
                </div>
              </div>
              <div className="form-item">
                <label>휴대폰번호*</label>
                <Form.Item name='phone'>
                  <InputItem required ref={refs.current.phone} placeholder="휴대전화 입력하세요*" />
                </Form.Item>
              </div>
            </div>

            <div className="form-column">
              <div className="form-item">
                <label>은행명*</label>
                  <SelectItem onChange={(e: any) => selectRef.current = e} ref={refs.current.bankName} options={options} defaultValue={''} />
              </div>
              <div className="form-item">
                <label>계좌번호*</label>
                <Form.Item name='bankNumber'>
                  <InputItem required ref={refs.current.accountNumber} placeholder="계좌번호는 숫자만 입력하세요*" />
                </Form.Item>
              </div>
              <div className="form-item">
                <label>예금주*</label>
                <Form.Item name='person'>
                  <InputItem required ref={refs.current.accountHolder} placeholder="예금주명*" />
                </Form.Item>
              </div>
              <div className="form-item">
                <label>환전비밀번호*</label>
                <Form.Item name='changePassword'>
                  <InputItem required ref={refs.current.exchangePassword} placeholder="" />

                </Form.Item>
              </div>
              <div className="form-item">
                <label>추천인*</label>
                <Form.Item name='recCode'>
                  <InputItem type="password" required ref={refs.current.recommender} placeholder="추천인 확인*" />
                </Form.Item>
                <div className="rounded-btn">
                  <RoundedButton type="button" onClick={checkRecCode} >중복확인</RoundedButton>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-gr">
            <CustomButton disabled={popupStates.isWaitting} type='submit'>가입신청</CustomButton>
          </div>
        </Form>
      </AuthWrapper>
    </AuthLayout>
  )
}

export default RegisterModal
